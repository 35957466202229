<template>
  <div class="my-vote">
    <div class="title" v-if="isVote">{{ $t('proposal.yourVote') }}</div>
    <div class="key-value" v-for="(item, index) in myVoteList" :key="index">
      <div v-if="item.amount" class="for-wrap">
        <div class="key" translate="no">
          {{ $gbUtils.formatBalance(item.amount / 1e18) }}
          {{ $t('proposal.ticket') }}
        </div>
        <div class="value ellipsis">
          <div class="text">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="reward" v-if="timeStatus === 2 && myTotalVote && totalReward">
      {{ $t('proposal.get') }}
      <span translate="no">
        {{
          $gbUtils.formatBalance(
            ((myTotalVote / dataSource.totalVote) * totalReward) / 1e18
          )
        }}
        {{ tokenName }}</span
      >
    </div>
    <div
      class="btn-group"
      v-if="(timeStatus === 2 || timeStatus === 4) && status === '10'"
    >
      <!--  dataSource.initiate.toUpperCase() === myAccount.toUpperCase() && -->
      <VanButton
        class="button"
        :class="{ 'end-btn': isVote }"
        :loading="btnLoading"
        @click="handleProposalFinsh"
        >{{ $t('proposal.endProposal') }}</VanButton
      >
    </div>
    <div
      class="btn-group"
      v-if="(timeStatus === 2 || timeStatus === 4) && redeemStatus"
    >
      <!--  dataSource.initiate.toUpperCase() === myAccount.toUpperCase() && -->
      <VanButton
        class="button"
        v-if="myVoteList.length"
        :loading="btnLoading"
        :disabled="status === '10'"
        @click="handleRedeem"
      >
        <span v-if="status === '10'">{{ $t('proposal.waitFinish') }}</span>
        <span v-else-if="status === '20'">{{ $t('proposal.redeem') }}</span>
      </VanButton>
    </div>
  </div>
</template>

<script>
import ContractDao from '@/contracts/contractDao'
import { mapState } from 'vuex'
export default {
  props: ['myVoteList', 'dataSource', 'tokenName', 'timeStatus', 'voteMembers', 'isRedeem', 'isVote', 'myTotalVote'],
  name: '',
  data () {
    return {
      totalReward: 0,
      btnLoading: false,
      voteList: [],
      status: '',
      redeemStatus: false
    }
  },
  computed: {
    ...mapState(['myAccount'])
  },
  methods: {
    async handleRedeem () {
      this.btnLoading = true
      const resp = await ContractDao.voteFinsh(this.dataSource.daoAt, this.dataSource.index)
      this.btnLoading = false
      if (resp.success) { this.redeemStatus = false }
    },
    async handleProposalFinsh () {
      this.btnLoading = true
      console.log('this.dataSource.daoAt', this.dataSource.daoAt)
      console.log('this.dataSource.index', this.dataSource.index)
      const resp = await ContractDao.proposalFinsh(this.dataSource.daoAt, this.dataSource.index)
      this.btnLoading = false
      if (!resp.success) return
      this.status = '20'
      this.$emit('proposalFinsh')
      this.$toast('success')
    }
  },
  watch: {
    myVoteList (val) {
      this.voteList = val
      const info = this.dataSource.uintParams
      this.totalReward = +info[2]
      this.status = this.dataSource.status
      this.uintParams = this.dataSource.uintParams
    }
  },
  mounted () {
    this.voteList = this.myVoteList

    const info = this.dataSource.uintParams
    this.status = this.dataSource.status
    this.redeemStatus = this.isRedeem

    if (info) { this.totalReward = +info[2] }
  }
}
</script>

<style scoped lang="scss">
.my-vote {
  margin-top: 15px;
  padding: 30px 20px;
  background: $bg-color1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .title {
    color: #d4cbc2;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .key-value {
    .for-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }

    .key {
      color: #888888;
      font-weight: 600;
    }
    .value {
      box-sizing: border-box;
      height: 34px;
      width: 117px;
      border-radius: 8px;
      color: #b6c5da;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px;
      background: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      background: -webkit-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      background: -o-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
      .text {
        background: #1e1e36;
        height: 32px;
        width: 100%;
        border-radius: 8px;
        text-align: center;
        line-height: 33px;
      }
    }
  }
  .reward {
    padding: 10px 0;
    font-size: 12px;
    font-weight: 400;
    color: #ff754c;
    text-align: center;
  }
  .btn-group {
    display: flex;
    justify-content: center;
  }
  .button {
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 39px;
    &.end-btn {
      margin-bottom: 10px;
    }
  }
}
</style>
