<template>
  <VanPopup
    style="
      box-sizing: border-box;
      width: 93%;
      max-width: 350px;
      padding: 40px 40px;
      background: #1f1d39;
    "
    round
    v-model="show"
    @click-overlay="closePop"
  >
    <div class="main-title">{{ $t('proposal.vote') }}</div>
    <div class="vote-select">
      <div v-if="!isSingle">
        <div class="title">{{ $t('proposal.voteOptions') }}</div>
        <div class="select-wrap">
          <div class="vote-item" v-for="item in options" :key="item">
            {{ dataSource.options[item] }}
          </div>
        </div>
      </div>
      <div v-else class="single">
        <div class="title">{{ $t('proposal.voteOptions') }}</div>
        <div class="vote-item" v-for="item in options" :key="item">
          {{ dataSource.options[item] }}
        </div>
      </div>
      <div class="token-wrap" v-if="dao.lp !== noLp">
        <div class="token-item" @click="handleIsLp(false)">
          <div :class="{ text: isLp }">
            {{ $t('proposal.useToken') }}
          </div>
        </div>
        <div @click="handleIsLp(true)" class="token-item">
          <div :class="{ text: !isLp }">
            {{ $t('proposal.useLp') }}
          </div>
        </div>
      </div>
    </div>
    <div class="input-wrap">
      <div class="title" v-if="isLp">
        {{ $t('proposal.lpValue') }}{{ $gbUtils.handleRound(lpToToken)
        }}{{ $t('proposal.ticket') }}
      </div>
      <input
        v-model="ticket"
        input-align="center"
        :placeholder="$t('common.pleaseInput')"
        type="number"
        class="input"
      />
      <div class="account">{{ $t('proposal.balance') }}</div>
      <div class="account-value" v-if="isLp" translate="no">
        {{ $gbUtils.formatBalance(lpBalance) }} LP≈{{
          $gbUtils.formatBalance(lpBalance * lpToToken)
        }}{{ $t('proposal.ticket') }}
      </div>
      <div class="account-value" v-else translate="no">
        {{ $gbUtils.formatBalance(tokenBalance) }}{{ tokenName }}≈{{
          $gbUtils.formatBalance(tokenBalance)
        }}{{ $t('proposal.ticket') }}
      </div>
    </div>
    <div class="btn-group _flex">
      <VanButton
        class="button"
        :loading="btnLoading"
        :disabled="authToken"
        v-if="!isLp"
        @click="approveToken"
        >{{ $t('proposal.auth') }}</VanButton
      >
      <VanButton
        class="button"
        :loading="btnLoading"
        :disabled="authLp"
        v-else
        @click="approveLP"
        >{{ $t('proposal.authLp') }}</VanButton
      >
      <VanButton
        class="button"
        :disabled="ticket <= 0 || (!isLp && !authToken) || (isLp && !authLp)"
        :loading="btnLoading"
        @click="handleVote"
        >{{ $t('proposal.stake') }}</VanButton
      >
    </div>
    <div class="notice">{{ $t('proposal.stakeNotice') }}</div>
  </VanPopup>
</template>

<script>
import ContractToken from '@/contracts/contractToken'
import config from '@/config/app.config'
import ContractDao from '@/contracts/contractDao'
export default {
  name: '',
  props: ['options', 'dataSource', 'dao'],
  data () {
    return {
      show: true,
      ticket: null,
      isSingle: false,
      isLp: false,
      noLp: '0x0000000000000000000000000000000000000000',
      tokenBalance: 0,
      lpBalance: 0,
      btnLoading: false,
      authToken: false,
      authLp: false,
      lpToToken: 0,
      tokenName: ''
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    handleIsLp (bool) {
      this.isLp = bool
    },
    async getTokenName () {
      const resp = await ContractToken.getSymbol(this.dao.token)
      if (!resp.success) return
      this.tokenName = resp.result
    },
    async getTokenInfo () {
      const balanceRes = await ContractToken.balanceOf(this.dao.token)
      if (balanceRes.success) {
        this.tokenBalance = balanceRes.result
      }
    },
    async getLpInfo () {
      const resp = await ContractToken.balanceOf(this.dao.lp)
      if (resp.success) {
        this.lpBalance = resp.result
        console.log('this.lpBalance', this.lpBalance)
      }
    },
    async handleVote () {
      this.btnLoading = true
      // console.log('amounts', this.ticket)
      // console.log('options', this.options)
      let amounts = []
      this.options.forEach(item => {
        amounts.push(this.$web3.toWei(this.ticket / this.options.length))
      })
      console.log(this.amounts)
      const resp = await ContractDao.vote(this.dataSource.daoAt, this.dataSource.index, this.isLp, this.options, amounts)
      this.btnLoading = false
      if (!resp.success) return
      if (this.isLp) {
        console.log('this.isLp', this.isLp)
        console.log('this.lpToToken', this.lpToToken)
        amounts = amounts.map(item => {
          console.log(item)
          console.log('+item * +this.lpToToken', +item * +this.lpToToken)
          return +item * +this.lpToToken
        })
      }
      this.$toast('success')
      this.$emit('success', amounts)
      this.closePop()
    },
    async getTokenAllowance () {
      // 获取授权额度
      this.btnLoading = true
      console.log('this.dao.token', this.dao.token)
      const resp = await ContractToken.allowance(this.dao.token, config.daoAddress)
      if (resp.success) {
        this.authToken = resp.result > 0
      }
      this.btnLoading = false
    },
    async getLpAlllowance () {
      this.btnLoading = true
      const resp = await ContractToken.allowance(this.dao.lp, config.daoAddress)
      if (resp.success) {
        this.authLp = resp.result > 0
      }
      this.btnLoading = false
    },
    // 授权Token
    async approveToken () {
      this.btnLoading = true
      const resp = await ContractToken.approve(this.dao.token, config.daoAddress, 100000000000000000) // 授权token
      this.btnLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.authToken = true
    },
    // 授权Token
    async approveLP () {
      this.btnLoading = true
      const resp = await ContractToken.approve(this.dao.lp, config.daoAddress, 100000000000000000) // 授权LP
      this.btnLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.authLp = true
    },
    async getLpTicket () {
      const resp = await ContractDao.lpToTokenPrice(this.dao.lp, this.dao.token)
      if (!resp.success) return
      this.lpToToken = resp.result
    }
  },
  watch: {
    isLp (val) {
      if (val) {
        this.getLpAlllowance()
      }
    }
  },
  mounted () {
    this.isSingle = !this.dataSource.mutilOption
    this.getTokenInfo()
    this.getTokenName()
    this.getTokenAllowance()
    if (this.dao.lp !== this.noLp) {
      this.getLpInfo()
      this.getLpTicket()
    }
  }
}
</script>

<style scoped lang="scss">
.main-title {
  font-weight: 600;
  color: $main-color1;
  font-size: 20px;
  text-align: center;
}
.single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .vote-item {
    width: 70%;
    min-height: 34px;
    font-weight: 600;
    color: $base-color1;
    text-align: center;
    line-height: 34px;
    background: $linear-bg3;
    border-radius: 8px;
  }
}
.vote-select {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #d4cbc2;
  .select-wrap {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .vote-item {
      margin-top: 15px;
      width: 100%;
      min-height: 34px;
      font-weight: 600;
      color: $base-color1;
      text-align: center;
      line-height: 34px;
      background: $linear-bg3;
      border-radius: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .token-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .token-item {
      margin-top: 30px;
      width: 48%;
      height: 34px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      line-height: 34px;
      background: $linear-bg3;
      border-radius: 8px;
      padding: 1px;
      .text {
        background-color: #1f1d39;
        height: 32px;
        border-radius: 8px;
      }
    }
  }
  .active {
    background-color: #00d093 !important;
  }
}
.input-wrap {
  margin-top: 30px;
  padding: 30px 20px;
  background: #26263d;
  border-radius: 8px;
  .title {
    color: $main-color1;
  }
  .input {
    margin-top: 15px;
    height: 35px;
    background: #4b4b5e;
    border-radius: 8px;
    line-height: 35px;
    width: 100%;
    border: none;
    text-align: center;
  }
  .account {
    margin-top: 25px;
    text-align: center;
    color: $main-color1;
    font-weight: 600;
    font-size: 14px;
  }
  .account-value {
    margin-top: 10px;
    text-align: center;
    color: $base-color1;
    font-weight: 600;
    font-size: 20px;
    word-break: break-word;
  }
}
.btn-group {
  display: flex;
  // justify-content: center;
}
.button {
  width: 48%;
  // padding: 0 50px;
  margin: 20px 0;
  height: 39px;
  background: $blue-bg;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #fff !important;
}
.notice {
  text-align: center;
  color: #ff754c;
}
</style>
