<template>
  <div class="proposal-detail-info">
    <!-- 提案信息 -->
    <ProposalInfo :dataSource="dataSource" :dao="currentDao" :tokenName="tokenName" :timeStatus="timeStatus"
      :isRedeem="isRedeem"></ProposalInfo>
    <!-- 我的投票 -->
    <MyVote v-if="isVote || (timeStatus === 2 && dataSource.status === '10')" :dataSource="dataSource"
      :myVoteList="myVoteList" :myTotalVote="myTotalVote" :tokenName="tokenName" :timeStatus="timeStatus"
      :voteMembers="voteNum" :isRedeem="isRedeem" :isVote="isVote" @proposalFinsh="proposalFinsh"></MyVote>
    <!-- 多选项投票 -->
    <DiverseVote ref="DiverseVote" v-if="dataSource.mutilOption" :dataSource="dataSource" :dao="currentDao"
      :tokenName="tokenName" @show="handleVoteShow" :timeStatus="timeStatus"></DiverseVote>
    <!-- 单选项投票 -->
    <SingleVote v-else :dataSource="dataSource" :dao="currentDao" :tokenName="tokenName" @show="handleVoteShow"
      :timeStatus="timeStatus" ref="SingleVote"></SingleVote>
    <!-- 投票记录 -->
    <VoteRecords v-if="timeStatus && voteList.length > 0" :dataSource="dataSource" :dao="currentDao"
      :voteList="voteList" :tokenName="tokenName" :voteMembers="voteNum"></VoteRecords>
    <VoteTime :dataSource="dataSource"></VoteTime>
    <VotePop v-if="voteShow" @close="handleVoteShow" @success="handleVoteSuccess" :dataSource="dataSource"
      :options="options" :dao="currentDao"></VotePop>
  </div>
</template>

<script>
import { getVoteRecord, getVoteStatus, getMemberNumber } from "@/utils/article";
import VoteTime from './components/proposal/VoteTime.vue'
import MyVote from './components/proposal/MyVote.vue'
import ProposalInfo from './components/proposal/ProposalInfo.vue'
import SingleVote from './components/proposal/SingleVote.vue'
import DiverseVote from './components/proposal/DiverseVote.vue'
import VoteRecords from './components/proposal/VoteRecords.vue'
import VotePop from './components/proposal/VotePop.vue'
import ContractToken from '@/contracts/contractToken'
import ContractDao from '@/contracts/contractDao'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  name: '',
  data() {
    return {
      voteShow: false,
      options: [],
      currentDao: {},
      dataSource: {},
      tokenName: '',
      pageSize: 0,
      voteList: [],
      voteMembers: [],
      voteNum: 0,
      timeStatus: 0, // 0未开始 1投票中 2已结束
      myVoteList: [],
      myTotalVote: 0,
      isVote: false,
      isRedeem: false,
      lpToToken: 0
    }
  },
  // watch: {
  //   isRedeem: {
  //     handler(newName, oldName) {
  //       console.log('obj.a changed');
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  computed: {
    ...mapState(['daoList', 'proposalDetail'])
  },
  methods: {
    handleVoteShow(options) {
      this.options = options
      this.voteShow = !this.voteShow
    },
    async getTokenInfo() {
      const nameRes = await ContractToken.getSymbol(this.currentDao.token)
      if (nameRes.success) {
        this.tokenName = nameRes.result
      }
    },
    proposalFinsh() {
      this.dataSource.status = '20'
    },
    handleVoteSuccess(amount) {
      if (this.dataSource.mutilOption) {
        this.options.forEach((item, index) => {
          const voteIndex = this.$refs.DiverseVote.voteArr.findIndex(voteItem => {
            return +item === +voteItem.index
          })
          this.$refs.DiverseVote.voteArr[voteIndex].num = +this.$refs.DiverseVote.voteArr[voteIndex].num + +amount[index]
          this.$refs.DiverseVote.totalVotes += +amount[index]
        })
      } else {
        this.options.forEach((item, index) => {
          const voteIndex = this.$refs.SingleVote.voteArr.findIndex(voteItem => {
            return +item === +voteItem.index
          })
          this.$refs.SingleVote.voteArr[voteIndex].num = +this.$refs.SingleVote.voteArr[voteIndex].num + +amount[index]
          this.$refs.SingleVote.totalVotes += +amount[index]
        })
      }
    },
    async getLpTicket() {
      const resp = await ContractDao.lpToTokenPrice(this.currentDao.lp, this.currentDao.token)
      if (!resp.success) return
      this.lpToToken = resp.result
    },
    async getVoteList() {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.voteRecordList(this.dataSource.daoAt, this.dataSource.index, 0, 1000)
      // console.log('resp', resp)
      for (let i = 0; i < resp.result.length; i++) {
        if (resp.result[i].voter === '0x0000000000000000000000000000000000000000') { break }
        this.voteMembers.push(resp.result[i].voter)
        this.voteList.push(resp.result[i])
      }
      // console.log(this.voteList[0], '0000')
      this.$emit('changeLoading', false)

      getMemberNumber({ dao: this.dataSource.daoAt, id: this.dataSource.daoAt + '_' + this.dataSource.index }).then(res => {
        if (res.data.proposalEntities.length > 0) {
          this.voteNum = res.data.proposalEntities[0].votePerson
        } else {
          this.voteNum = 0
        }

      })

      // this.voteNum = this.$_.uniq(this.voteMembers).length
    },
    async getVoteRecords() {
      var list = []
      await getVoteStatus({ creator: store.state.myAccount, daoId: this.dataSource.daoAt, proposal: this.dataSource.daoAt + '_' + this.dataSource.index }).then(res => {
        if (res.data.userProposalRecordEntities.length > 0) {
          this.isRedeem = res.data.userProposalRecordEntities[0].redeemStatus
        }
        
      })
      getVoteRecord({ voter: store.state.myAccount, daoId: this.dataSource.daoAt, proposal: this.dataSource.daoAt + '_' + this.dataSource.index }).then(res => {
        list = res.data.userProposalOptionRecordEntities;

        for (let i = 0; i < list.length; i++) {
          if (list[i].voter === '0x0000000000000000000000000000000000000000') { break }
          this.isVote = true

          const str = list[i].options.split('&')
          const amounts = list[i].amounts.split('&')

          str.forEach((item, index) => {
            if (+list[i].lpAmount) {
              this.myVoteList[item].amount += +amounts[index] * +this.lpToToken
            } else {
              this.myVoteList[item].amount += +amounts[index]
            }
          })
          this.myTotalVote += +list[i].tokenAmount
        }
      })




      // const resp = await ContractDao.voteRecord(
      //   this.dataSource.daoAt, this.dataSource.index, 100
      // )
      // console.error(resp)
      // for (let i = 0; i < resp.result.length; i++) {
      //   if (resp.result[i].voter === '0x0000000000000000000000000000000000000000') { break }
      //   this.isVote = true
      //   this.isRedeem = resp.result[i].status
      //   resp.result[i].options.forEach((item, index) => {
      //     console.log('+resp.result[i].tokenAmount', +resp.result[i].tokenAmount)
      //     if (+resp.result[i].lpAmount) {
      //       console.log('lp')
      //       this.myVoteList[item].amount += +resp.result[i].amounts[index] * +this.lpToToken
      //     } else {
      //       console.log('token')
      //       this.myVoteList[item].amount += +resp.result[i].amounts[index]
      //     }
      //   })
      //   console.log(' this.myVoteList', this.myVoteList)
      //   this.myTotalVote += +resp.result[i].tokenAmount
      // }
    }
  },
  mounted() {
    this.dataSource = this.proposalDetail
    const index = localStorage.getItem('daoIndex') || 0
    this.currentDao = this.daoList[index] || this.daoList[0]
    if (this.currentDao.lp !== '0x0000000000000000000000000000000000000000') {
      this.getLpTicket()
    }
    this.dataSource.options.forEach(item => {
      this.myVoteList.push({
        name: item,
        amount: 0
      })
    })
    // console.log(this.myVoteList, 'myVoteList')
    this.getVoteRecords()
    this.getVoteList()
    this.getTokenInfo()
    this.timeStatus = +this.$route.query.status
  },
  components: { VoteTime, VoteRecords, ProposalInfo, MyVote, DiverseVote, SingleVote, VotePop }
}
</script>

<style scoped lang="scss">
.proposal-detail-info {
  box-sizing: border-box;
  padding: 15px;
  font-size: 12px;
}
</style>
