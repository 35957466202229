<template>
  <div class="info">
    <div class="title">{{ proposalInfo[0] }}</div>
    <div class="vote-info">
      <div class="status status1" v-if="timeStatus === 0">
        {{ $t('proposal.wairStart') }}
      </div>
      <div class="status" v-else-if="timeStatus === 1">
        {{ $t('proposal.voting') }}
      </div>
      <div class="status status4" v-else-if="timeStatus === 4">
        {{ $t('proposal.waitRedeem') }}
      </div>
      <div class="status status2" v-else>{{ $t('proposal.ended') }}</div>
      <div class="dao-info">
        <div class="logo">
          <img :src="daoParams[0]" alt="" />
        </div>
        <div class="name" v-if="lang === 'ZH'">
          {{ daoParams[1] }}
        </div>
        <div class="name" v-else>{{ daoParams[2] }}</div>
      </div>
    </div>
    <div class="reward" v-if="+uintParams[2]">
      {{ $t('proposal.voteReward') }}:{{ +uintParams[2] / 1e18 }}
      {{ tokenName }}
    </div>
    <div class="content" v-html="analysisUrl(proposalInfo[1])">
      <!-- {{proposalInfo[1]  }} -->
    </div>
    <div class="address" translate="no">
      {{ $gbUtils.formatAddress(dataSource.initiate, '...', '6')
      }}{{ $t('proposal.initiate') }}
    </div>
    <div
      class="button"
      v-if="
        dataSource.initiate &&
        dataSource.initiate.toUpperCase() === myAccount.toUpperCase() &&
        !timeStatus
      "
      @click="handleModify"
    >
      {{ $t('proposal.modify') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['dataSource', 'dao', 'tokenName', 'timeStatus', 'isRedeem'],
  name: '',
  data () {
    return {
      daoInfo: {},
      daoParams: {},
      proposalInfo: {},
      redeemStatus: true,
      uintParams: []
    }
  },
  methods: {
    ...mapActions(['chanegProposalDetail']),
    handleModify () {
      var info = this.dataSource
      info.token = this.dao.token
      this.chanegProposalDetail(info)
      // sessionStorage.setItem('proposalDetail', JSON.stringify(info))
      this.$gbUtils.handleToPage('/modifyProposal')
    },
    analysisUrl(str) {
      if (!str) {
        return '';
      }
      //先计算里面有多少个需要替换的链接
      var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
      var htmlStr = str.replace(reg, `<a target=_blank href="$1$2"> $1$2 </a>`);
      return htmlStr;
    },
  },
  computed: {
    ...mapState(['lang', 'myAccount'])
  },
  mounted () {
    this.daoInfo = this.dao
    this.daoParams = this.daoInfo.params
    this.proposalInfo = this.dataSource.name_desc
    this.redeemStatus = this.isRedeem
    this.uintParams = this.dataSource.uintParams
  },
  watch: {
    dao (val) {
      this.daoInfo = val
      this.daoParams = val.params
    },
    dataSource (val) {
      this.proposalInfo = val.name_desc
      this.uintParams = val.uintParams
      this.redeemStatus = this.isRedeem
    }
  }
}
</script>
<style lang="scss">
  .content{
    a:link{
      color:#0078FF;
       text-decoration:none;
    }
    a:visited{
      color:#0078FF;
       text-decoration:none;
    }
    a:active{
      color:#0078FF;
       text-decoration:none;
    }
  }
    
</style>
<style scoped lang="scss">

.info {
  padding: 30px 20px;
  background: $bg-color1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .title {
    font-weight: 600;
    color: $base-color1;
    font-size: 20px;
    text-align: center;
  }
  .vote-info {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status {
      height: 25px;
      background: #125afd;
      border-radius: 12px;
      line-height: 25px;
      padding: 0 10px;
    }
    .status1 {
      background-color: #d1ac89;
    }
    .status2 {
      background-color: #342d22;
    }
    .status4 {
      background-color: #cead8d;
    }
    .dao-info {
      display: flex;
      align-items: center;
      color: #888888;
      .logo {
        img {
          width: 32px;
          height: 28px;
          margin-right: 3px;
          object-fit: contain;
        }
      }
    }
  }
  .reward {
    height: 30px;
    background: #e7e7e7;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    color: #ff754c;
    margin-bottom: 15px;
  }
  .content {
    word-break: break-all;
    line-height: 22px;
    color: $base-color1;
    
  }
  .button {
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    line-height: 39px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    margin-top: 25px;
    text-align: center;
  }
  .address {
    margin-top: 10px;
    color: #888888;
    text-align: right;
  }
}
</style>
