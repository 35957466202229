<template>
  <!-- 时间信息 -->
  <div class="time-info">
    <div class="title">{{ $t('proposal.info') }}</div>
    <div class="item">
      <div class="key">{{ $t('proposal.startTime') }}</div>
      <div class="value">
        {{ starTime }}
      </div>
    </div>
    <div class="item">
      <div class="key">{{ $t('proposal.endTime') }}</div>
      <div class="value">
        {{ endTime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataSource'],
  name: '',
  data () {
    return {
      starTime: '',
      endTime: ''
    }
  },
  methods: {

  },
  watch: {
    dataSource (val) {
      console.log('val', val)
      this.starTime = this.$moment(val.uintParams[3] * 1000).format('YYYY-MM-DD HH:mm:ss')
      this.endTime = this.$moment(val.uintParams[4] * 1000).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted () {
    if (this.dataSource.uintParams) {
      this.starTime = this.$moment(this.dataSource.uintParams[3] * 1000).format('YYYY-MM-DD HH:mm:ss')
      this.endTime = this.$moment(this.dataSource.uintParams[4] * 1000).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="scss">
.time-info {
  padding: 0 10px;
  font-weight: 600;
  color: #d4cbc2;
  .title {
    font-size: 16px;
    padding: 20px 0;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #f9f9f9;
  }
}
</style>
