<template>
  <div>
    <div class="vote" v-if="timeStatus === 1">
      <div
        class="item"
        v-for="(item, index) in dataSource.options"
        :key="index"
        @click="handleSelectVote(index)"
      >
        <div :class="{ 'item-content': !options[index] }" class="center">
          {{ item }}
        </div>
      </div>
      <VanButton
        class="button"
        :disabled="options.length <= 0"
        @click="handleShowVote"
      >
        {{ $t('proposal.vote') }}
      </VanButton>
    </div>
    <!-- 当前结果 -->
    <div class="vote-info">
      <div class="title">{{ $t('proposal.result') }}</div>
      <div class="item" v-for="item in voteArr" :key="item.index">
        <div class="item-info">
          <div class="name">{{ item.name }}</div>
          <div class="usdt" translate="no">
            {{ $gbUtils.formatTwoBalance(item.num / 1e18)
            }}{{ $t('proposal.ticket') }}
          </div>
          <div class="percent" translate="no">
            {{
              $gbUtils.formatTwoBalance((+item.num / totalVotes) * 100) || 0
            }}%
          </div>
        </div>
        <div class="line-bg">
          <div
            class="line"
            :style="{
              width: `${(+item.num / totalVotes) * 100 || 0}%`,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['dataSource', 'dao', 'timeStatus', 'tokenName'],
  data () {
    return {
      options: [],
      totalVotes: 0,
      voteResult: [],
      voteArr: []
    }
  },
  methods: {
    handleSelectVote (index) {
      this.$set(this.options, index, !this.options[index])
    },
    handleShowVote () {
      const options = []
      this.options.forEach((item, index) => {
        if (item) {
          options.push(index)
        }
      })
      this.$emit('show', options)
    }
  },
  watch: {
    dataSource (val) {
      this.totalVotes = 0
      this.dataSource.votes.forEach((item, index) => {
        this.totalVotes += parseInt(item)
        this.voteArr.push({
          index: index,
          num: item,
          name: this.dataSource.options[index]
        })
      })
      this.voteArr = [...this.voteArr].sort((a, b) => {
        return +b.num - +a.num
      })
    }
  },
  mounted () {
    this.totalVotes = 0
    this.dataSource.votes.forEach((item, index) => {
      this.totalVotes += parseInt(item)
      this.voteArr.push({
        index: index,
        num: item,
        name: this.dataSource.options[index]
      })
    })
    this.voteArr = [...this.voteArr].sort((a, b) => {
      return +b.num - +a.num
    })
  }
}
</script>

<style scoped lang="scss">
.vote {
  padding: 30px 20px;
  .item {
    margin-top: 10px;
    height: 39px;
    background: $linear-bg3;
    border-radius: 8px;
    text-align: center;
    line-height: 39px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 1px;
    .item-content {
      background-color: #060621;
      width: 100%;
      height: 37px;
      border-radius: 8px;
    }
    .center {
      text-align: center;
      width: 100%;
    }
  }
  .active {
    background-color: #00d093 !important;
  }
  .button {
    width: 100%;
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    line-height: 39px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    margin-top: 25px;
    text-align: center;
  }
}
.vote-info {
  margin-top: 15px;
  padding: 30px 20px;
  background: $bg-color1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .title {
    color: $base-color1;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .item {
    margin-bottom: 20px;
    .item-info {
      color: $main-color1;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        flex: 1;
        max-width: 30%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .usdt {
        flex: 1;
        text-align: center;
      }
      .percent {
        text-align: right;
        font-size: 18px;
        color: $base-color1;
        flex: 1;
      }
    }
    .line-bg {
      margin-top: 5px;
      height: 10px;
      border-radius: 5px;
      background: #39394e;
      background-size: 100% 100%;
      .line {
        background: linear-gradient(90deg, #5e4fff 0%, #9400ff 100%);
        border-radius: 5px;
        height: 10px;
        width: 50%;
      }
    }
  }
}
</style>
