<template>
  <div class="vote-list">
    <div class="title">
      <div class="name">{{ $t('proposal.voteRecords') }}</div>
      <div class="joined">
        <span translate="no">{{ voteNum }} </span>
        {{ $t('proposal.membersJoin') }}
      </div>
    </div>
    <div class="vote-record" v-for="(item, index) in list" :key="index">
      <div class="record-info">
        <div class="record-header">
          <div class="address" translate="no">
            {{ $gbUtils.formatAddress(item.voter) }}
          </div>
          <div class="usdt" translate="no">
            {{ $gbUtils.formatTwoBalance(item.tokenAmount / 1e18)
            }}{{ $t('proposal.ticket') }}
          </div>
        </div>
        <div class="tag-group">
          <div class="tag" v-for="item in item.options" :key="item">
            <div class="text">
              {{ dataSource.options[item] }}
            </div>
          </div>
        </div>
      </div>
      <!-- <svg class="icon" aria-hidden="true">
        <use xlink:href="#a-24gf-thumbsDown4"></use>
      </svg> -->
    </div>
    <div
      class="button"
      @click="showMoer"
      v-if="this.list.length !== this.voteList.length"
    >
      {{ $t('proposal.more') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentDao', 'dataSource', 'voteList', 'tokenName', 'voteMembers'],
  name: '',
  data () {
    return {
      voteNum: 0,
      list: []
    }
  },
  methods: {
    showMoer () {
      if (this.list.length === this.voteList.length) return
      this.list = this.list.concat(this.voteList.slice(10))
      this.pageNumber++
    }
  },
  mounted () {
    this.voteNum = this.voteMembers
    this.list = this.voteList.slice(0, 10)
    console.log('this.voteList', this.voteList)
  },
  watch: {
    voteMembers (val) {
      console.log('val', val)
      this.voteNum = val
      this.list = this.voteList.slice(0, 10)
      this.pageNumber++
    }
  }
}
</script>

<style scoped lang="scss">
.vote-list {
  margin-top: 40px;
  .title {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      font-weight: 600;
      color: #d4cbc2;
      font-size: 14px;
    }
    .joined {
      font-weight: 600;
      color: $main-color1;
      span {
        color: #105cfb;
      }
    }
  }
  .vote-record {
    margin-top: 15px;
    padding: 10px 20px;
    background: $bg-color1;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    align-items: center;
    .record-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .address {
        font-size: 14px;
        color: $base-color1;
        font-weight: 600;
        flex: 1;
      }
      .usdt {
        color: $base-color1;
      }
    }
    .tag-group {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .tag {
        margin-top: 5px;
        padding: 0 1px;
        line-height: 20px;
        height: 20px;
        margin-right: 10px;
        background: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
        background: -webkit-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
        background: -o-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
        border-radius: 10px;
        color: $base-color1;
        padding: 1px;
        .text {
          width: 100%;
          height: 18px;
          padding: 0 15px;
          background-color: #1e1e36;
          border-radius: 10px;
        }
      }
    }
  }
  .icon {
    margin-left: 5px;
    width: 50px;
    height: 50px;
  }
  .button {
    width: 132px;
    width: 40%;
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    line-height: 39px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    font-size: 14px;
    margin: 30px auto;
  }
}
</style>
